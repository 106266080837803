html, body {
    padding: 0 !important;
    margin: 0 !important;
    background-color: #fafffa !important;
}

.MuiAppBar-root {
    background-color: #4AA560 !important;
    color: #fff !important;
}

.MuiFab-root {
    background-color: #4AA560 !important;
    color: #fff !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Use container on the main content div on each page*/
.container {
    width: 100%;
    height: 100%;
    padding: 32px 8px;
    position: relative
}

.detail {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.value {
    color: green;
}

.signatureCanvas {
    width: 500px;
}

.ticker {
    width: 100%;
}